import AnswerCard from "../answer_card";

import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";

interface QuestionStageProps {
  text: string; // Question text.
  points: number; // Question points.
  answers: {
    text: string;
    correct?: boolean;
    selected: boolean;
    clickable: boolean;
    onAnswer?: () => void;
  }[]; // Question answers.
  answerRevealed: boolean; // Whether the answer has been revealed.
  correct: boolean; // Whether the answer was correct.
  answered: boolean; // Whether the question has been answered and is waiting on results.
}

export default function QuestionStage(props: QuestionStageProps) {
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h2" component="h3">
        {props.text}
      </Typography>
      <Typography variant="h4" component="h4">
        {props.points} points
      </Typography>

      {props.answered && (
        <Typography variant="h4" component="h5">
          Answered question. Waiting on results...
        </Typography>
      )}
      {
        // If the answer has been revealed, show whether the answer was correct.
        props.answerRevealed &&
          (props.correct ? (
            <Typography variant="h3" component="p" color="green">
              Correct
            </Typography>
          ) : (
            <Typography variant="h3" component="p" color="error">
              Incorrect
            </Typography>
          ))
      }

      <Grid
        container
        spacing={2}
        columns={2}
        sx={{
          p: 2,
          flexGrow: 1,
        }}
      >
        {props.answers.map((answer, index) => {
          return (
            <Grid
              container
              item
              xs={6}
              direction="column"
              key={`question-answers-${index}`}
              sx={{
                height: 200,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <AnswerCard
                  text={answer.text}
                  correct={answer.correct || false}
                  selected={answer.selected || false}
                  revealed={props.answerRevealed}
                  onClick={answer.onAnswer}
                  clickable={answer.clickable}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
