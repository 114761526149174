import { Card, CardActionArea, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface NotStartedPlayerProps {
  name: string;
  onKick: () => void;
}

export default function NotStartedPlayer(props: NotStartedPlayerProps) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 200,
      }}
    >
      <CardActionArea
        sx={{
          p: 2,
          "&:hover": {
            textDecoration: "line-through",
            textDecorationColor: theme.palette.error.main,
            color: theme.palette.error.main,
          },
        }}
        onClick={props.onKick}
      >
        <Typography noWrap variant="h6" component="p">
          {props.name}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
