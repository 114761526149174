import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { findSession } from "../api/api";

import { Box } from "@mui/system";
import { Typography, TextField, Button } from "@mui/material";

export default function JoinGame() {
  const navigate = useNavigate();
  const [gameCode, setGameCode] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleGameCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 6) return;
    setGameCode(event.target.value.replace(/[^0-9.]/g, ""));
  };

  const handleJoinGame = async () => {
    let sessionId;
    try {
      sessionId = await findSession(gameCode);
    } catch (error: any) {
      setError(error.message);
    }
    if (sessionId) {
      navigate(`/game/${sessionId}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        minHeight: "85vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <TextField
          value={gameCode}
          onChange={handleGameCodeChange}
          label="Game Code"
          sx={{
            my: 1,
            input: {
              textAlign: "center",
            },
          }}
        />
        <Button variant="contained" onClick={handleJoinGame}>
          Join Game
        </Button>
      </Box>
    </Box>
  );
}
