import { Box } from "@mui/system";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Link,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";
import {
  Outlet,
  Link as RouterLink,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { supabase } from "../helpers/supabase";
import { User } from "@supabase/supabase-js";
import { parseAuthErrorHash } from "../helpers/parseAuthErrorHash";

export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userButtonAnchorEl, setUserButtonAnchorEl] =
    useState<null | HTMLElement>(null);
  const [session, setSession] = useState<null | any>(null);
  const [lastAuthState, setLastAuthState] = useState("");
  const open = Boolean(userButtonAnchorEl);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
  }>({
    open: false,
    message: "",
    severity: "error",
  });

  const authError = parseAuthErrorHash(location.hash);

  if (authError && !snackbar.open) {
    setSnackbar({
      open: true,
      message: authError.error_description,
      severity: "error",
    });
  }

  const getUser = async () => {
    const user = (await supabase.auth.getUser()).data.user;
    setUser(user);
  };

  supabase.auth.onAuthStateChange((event, session) => {
    switch (event) {
      case "PASSWORD_RECOVERY":
        navigate("/my_account#change-password");
        break;
    }

    setSession(session);
    if (lastAuthState !== event) {
      getUser();
    }
    setLastAuthState(event);
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserButtonAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setUserButtonAnchorEl(null);
  };

  useEffect(() => {
    if (!user) {
      getUser();
    }
  });

  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => setDrawerOpen(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Quiz Game
            </Typography>
            {user ? (
              <Button
                color="inherit"
                id="user-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                {user.email}
              </Button>
            ) : (
              <Box>
                <Button component={RouterLink} to="/login" color="inherit">
                  Log In
                </Button>
                <Button component={RouterLink} to="/register" color="inherit">
                  Register
                </Button>
              </Box>
            )}
            <Menu
              id="user-menu"
              anchorEl={userButtonAnchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "user-button",
              }}
            >
              <MenuItem onClick={() => navigate("/my_account")}>
                My account
              </MenuItem>
              <MenuItem onClick={() => supabase.auth.signOut()}>
                Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setDrawerOpen(false)}
        >
          <List
            sx={{
              width: "100%",
            }}
          >
            <ListItem
              sx={{
                width: "100%",
                py: 2,
                px: 0,
                textAlign: "center",
              }}
            >
              <ListItemText
                primary={<Typography variant="h4">Quiz Game</Typography>}
              />
            </ListItem>
            <ListItem
              sx={{
                width: "100%",
                textAlign: "center",
                py: 1,
                px: 0,
              }}
            >
              <ListItemButton
                LinkComponent={RouterLink}
                href="/create_game"
                sx={{
                  textAlign: "center",
                }}
              >
                <ListItemText
                  primary={<Typography variant="h6">Create Game</Typography>}
                />
              </ListItemButton>
            </ListItem>

            <ListItem
              sx={{
                width: "100%",
                textAlign: "center",
                py: 1,
                px: 0,
              }}
            >
              <ListItemButton
                LinkComponent={RouterLink}
                href="/join"
                sx={{
                  textAlign: "center",
                }}
              >
                <ListItemText
                  primary={<Typography variant="h6">Join Game</Typography>}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Outlet />
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          });
        }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {
            setSnackbar({
              ...snackbar,
              open: false,
            });
            // Remove the hash from the URL
            navigate(location.pathname);
          }}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}
