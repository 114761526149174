import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        minHeight: "85vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          alignItems: "center",
        }}
      >
        <Typography variant="h2" component="h1">
          Quiz Game
        </Typography>
        <Button
          component={Link}
          to="/create_game"
          variant="contained"
          sx={{
            mt: 4,
            width: "200px",
            height: "50px",
          }}
        >
          Create Game
        </Button>
        <Button
          component={Link}
          to="/join"
          variant="contained"
          sx={{ mt: 2, width: "200px", height: "50px" }}
        >
          Join Game
        </Button>
      </Box>
    </Box>
  );
}
