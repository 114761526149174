import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";

import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
} from "@dnd-kit/modifiers";
import { useTheme, useMediaQuery, Collapse } from "@mui/material";

import ElementListElement, {
  ElementListElementProps,
} from "./element_list_element";

import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

interface ElementListTabProps {
  elements: ElementListElementProps[];
  onUpdateOrder: (oldIndex: number, newIndex: number) => void;
}

export default function ElementListTabTab(props: ElementListTabProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  let dndModifiers = md ? [restrictToVerticalAxis] : [restrictToHorizontalAxis];
  let dndStrategy = md
    ? verticalListSortingStrategy
    : horizontalListSortingStrategy;

  let elements = props.elements.map((element) => ({
    id: element.id,
    data: {
      order: element.order,
      title: element.title,
      content: element.content,
      selected: element.selected,
      onClick: element.onClick,
      onDeletion: element.onDeletion,
    },
  }));

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: {
          xs: "row",
          md: "column",
        },
        alignItems: "flex-start",
        overflowX: {
          xs: "auto",
          md: "hidden",
        },
        overflowY: {
          xs: "hidden",
          md: "auto",
        },
        width: "100%",
        height: "100%",
        touchAction: "none",
      }}
    >
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={dndModifiers}
      >
        <SortableContext items={elements} strategy={dndStrategy}>
          <TransitionGroup component={null}>
            {elements.map((element, index) => (
              <Collapse
                orientation={md ? "vertical" : "horizontal"}
                key={`element-list-element-${element.id}-collapse`}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: 2,
                  }}
                >
                  <Typography variant="h6" sx={{ width: 40 }}>
                    {index + 1}.
                  </Typography>
                  <ElementListElement
                    id={element.id}
                    order={index}
                    title={element.data.title}
                    content={element.data.content}
                    selected={element.data.selected}
                    onClick={element.data.onClick}
                    onDeletion={element.data.onDeletion}
                  />
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>
        </SortableContext>
      </DndContext>
    </Box>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    const activeItem = elements.find((element) => element.id === active.id);
    const overItem = elements.find((element) => element.id === over?.id);
    if (!activeItem || !overItem) {
      return;
    }

    props.onUpdateOrder(
      elements.indexOf(activeItem),
      elements.indexOf(overItem)
    );
  }
}
