import React, { useState } from "react";

import {
  Box,
  Card,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";

import { supabase } from "../../helpers/supabase";

export default function RequestPasswordReset() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
  }>({
    open: false,
    message: "",
    severity: "error",
  });

  const requestPasswordReset = async (email: string) => {
    let { data, error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
      return;
    }
    setSnackbar({
      open: true,
      message: "Password reset email sent",
      severity: "success",
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    // Clear errors
    setEmailError("");

    // Check if email is valid
    const regex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (!regex.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    // Call authentication function with email and password arguments
    requestPasswordReset(email);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          });
        }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {
            setSnackbar({
              ...snackbar,
              open: false,
            });
          }}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Card
        sx={{
          p: 2,
          m: 2,
          maxWidth: 600,
        }}
      >
        <Typography variant="h2" component="h1">
          Reset Password
        </Typography>
        <form>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Email Address"
            value={email}
            onChange={handleEmailChange}
            error={emailError !== ""}
            helperText={emailError}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              my: 2,
              width: "100%",
            }}
          >
            Reset Password
          </Button>
        </form>
      </Card>
    </Box>
  );
}
