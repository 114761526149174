import { createClient } from "@supabase/supabase-js";

if (
  !process.env.REACT_APP_SUPABASE_KEY ||
  !process.env.REACT_APP_SUPABASE_URL
) {
  throw new Error("Missing Supabase keys.");
}

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);
