import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/system";
import { Button, TextField, Typography } from "@mui/material";

interface EnterNameProps {
  error: string | null;
  handleJoinGame: (name: string) => void;
}

export default function EnterName(props: EnterNameProps) {
  const [playerName, setPlayerName] = useState("");
  const handlePlayerNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPlayerName(event.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "85vh",
      }}
    >
      <Typography
        variant="h4"
        component="h1"
        sx={{ textAlign: "center", mb: 2 }}
      >
        {props.error}
      </Typography>
      <TextField
        value={playerName}
        onChange={handlePlayerNameChange}
        label="Name"
        sx={{
          my: 1,
          input: {
            textAlign: "center",
          },
        }}
      />
      <Button
        variant="contained"
        onClick={() => props.handleJoinGame(playerName)}
        sx={{
          height: 50,
          width: 210,
        }}
      >
        Join Game
      </Button>
    </Box>
  );
}
