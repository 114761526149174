import { Card, TextField, Button } from "@mui/material";
import { Box } from "@mui/system";
import AnswerCreationCard from "./answer_creation_card";

interface AnswerCreationAnswer {
  text: string;
  correct: boolean;
}

export interface QuestionCreationQuestion {
  id: number;
  text: string;
  points: number;
  answers: AnswerCreationAnswer[];
  selected: boolean;
}

interface QuestionCreationCardProps {
  onDeletion: () => void;
  onQuestionChange: (question: QuestionCreationQuestion) => void;
  question: QuestionCreationQuestion;
}

export default function QuestionCreationCard(props: QuestionCreationCardProps) {
  const handleQuestionTextChange = (text: string) => {
    props.onQuestionChange({
      ...props.question,
      text: text,
    });
  };

  const handlePointsChange = (points: number) => {
    props.onQuestionChange({
      ...props.question,
      points: points,
    });
  };

  const handleAnswerDeletion = (index: number) => {
    const newAnswers = [...props.question.answers];
    newAnswers.splice(index, 1);
    props.onQuestionChange({
      ...props.question,
      answers: newAnswers,
    });
  };
  const handleAnswerTextChange = (answer: string, index: number) => {
    const newAnswers = [...props.question.answers];
    newAnswers[index].text = answer;
    props.onQuestionChange({
      ...props.question,
      answers: newAnswers,
    });
  };
  const handleAnswerCorrectChange = (correct: boolean, index: number) => {
    const newAnswers = [...props.question.answers];
    newAnswers[index].correct = correct;
    props.onQuestionChange({
      ...props.question,
      answers: newAnswers,
    });
  };
  const handleNewAnswer = () => {
    const newAnswers = [...props.question.answers];
    newAnswers.push({
      text: "",
      correct: false,
    });
    props.onQuestionChange({
      ...props.question,
      answers: newAnswers,
    });
  };

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          p: 2,
        }}
      >
        <TextField
          label="Question"
          variant="outlined"
          value={props.question.text}
          onChange={(e) => handleQuestionTextChange(e.target.value)}
          sx={{
            width: "100%",
            mr: 2,
          }}
        />
        <TextField
          id="question-points"
          type="number"
          label="Points"
          value={props.question.points}
          onChange={(e) => handlePointsChange(parseInt(e.target.value))}
          sx={{
            width: "100px",
          }}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          mt: 2,
        }}
      >
        <Button type="button" variant="contained" onClick={handleNewAnswer}>
          Add Answer
        </Button>
      </Box>

      <Box
        sx={{
          m: 2,
        }}
      >
        {props.question.answers.map((answer, index) => {
          return (
            <Box
              sx={{
                my: 2,
              }}
              key={`answer-creation-card-${index}`}
            >
              <AnswerCreationCard
                answerNumber={index + 1}
                answerText={answer.text}
                correct={answer.correct}
                onDeletion={handleAnswerDeletion}
                onAnswerTextChange={handleAnswerTextChange}
                onAnswerCorrectChange={handleAnswerCorrectChange}
              />
            </Box>
          );
        })}
      </Box>
    </Card>
  );
}
