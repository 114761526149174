import { pack, unpack } from "msgpackr";
import { supabase } from "../helpers/supabase";

export enum Session_Event {
  JOIN = 0, // CLIENT=>SERVER (Re)join a session using the session ID, private ID (for reconnecting), and name.
  STATUS = 1, // SERVER=>CLIENT Update status of the session.
  INTERACTION = 2, // CLIENT=>SERVER Player interaction with the session.
  INTERACTION_RESPONSE = 3, // SERVER=>CLIENT Response to a player interaction. (Error, OK, etc.)
  MESSAGE = 4, // SERVER=>CLIENT Message to display to the client.
  DISCONNECT = 5, // SERVER=>CLIENT Server is disconnecting client (Only sent to the player being disconnected).
}

export enum Session_Interaction {
  ANSWER = 0, // CLIENT=>SERVER Answer a question.
  START_GAME = 1, // CLIENT=>SERVER Start the game. (Requires host.)
  NEXT_QUESTION = 2, // CLIENT=>SERVER Go to the next question. (Requires host.)
  REVEAL_ANSWER = 3, // CLIENT=>SERVER Reveal the answer. (Requires host.)
  SHOW_LEADERBOARD = 4, // CLIENT=>SERVER Show the leaderboard. (Requires host.)
  END_SESSION = 5, // CLIENT=>SERVER End the session. (Requires host.)
  KICK_PLAYER = 6, // CLIENT=>SERVER Kick a player. (Requires host.)
  LEAVE = 7, // CLIENT=>SERVER Leave the session.
}

export enum Session_State {
  Created = 0, // Session has been created, players can join.
  Answering = 1, // Players may answer the current question.
  Answer_Displayed = 2, // Answer is being displayed. Players can see the correct answer, but not the next question.
  Leaderboard = 3, // Leaderboard is being displayed.
  Ended = 4, // Session has ended.
}

export enum Session_Interaction_Response {
  ANSWER_RECEIVED = 0, // SERVER=>CLIENT Answer received.
  JOINED_SESSION = 1, // SERVER=>CLIENT Joined session successfully.
  ERROR_JOINING_SESSION = 2, // SERVER=>CLIENT Error joining session.
  SESSION_NOT_FOUND = 3, // SERVER=>CLIENT Session not found.
}

export interface StatusAnswer {
  id: string;
  answer: string;
  correct?: boolean;
}

export interface StatusQuestion {
  id: string;
  question: string;
  points: number;
  answers?: StatusAnswer[];
}

export interface StatusPlayer {
  id: string;
  name: string;
  score: number;
}

// Used to transmit information about the session for new players and updates.
export interface Status {
  id: string;
  name: string;
  code: string;
  state: Session_State;
  numberOfQuestions: number;
  currentQuestionNumber: number;
  currentQuestion?: StatusQuestion;
  players: StatusPlayer[];
}

interface CurrentPlayer {
  name: string;
  id: string;
  privateId: string;
  score: number;
  lastAnswerId?: string;
}

export class Connection {
  // Current player
  player?: CurrentPlayer;
  type: "host" | "player";
  privateId?: string; // Private ID used to authenticate non-logged in players.
  userId?: string; // User ID of the player/host.
  status?: Status; // Current status of the session.
  socket: WebSocket;

  constructor(socket: WebSocket, type: "host" | "player") {
    this.socket = socket;
    this.type = type;

    this.socket.binaryType = "arraybuffer";
    this.socket.onmessage = (event) => {
      this.onMessage(event.data);
    };
  }

  private send(data: any) {
    this.socket.send(pack(data));
  }

  private updatedStatus(data: any) {
    this.status = {
      ...this.status,
      ...(data as Status),
    }; // Update the status with the new data.
    if (this.onStatusUpdate) {
      this.onStatusUpdate(this.status);
    }
    if (!data.players) {
      return;
    }
    let updatedPlayer = this.status.players.find(
      (p) => p.id === this.player?.id
    );
    if (updatedPlayer) {
      this.player = {
        ...(this.player as CurrentPlayer),
        name: updatedPlayer.name,
        score: updatedPlayer.score,
      };
    }
  }

  private joinedSession(data: any) {
    if (this.type === "host") {
      return;
    }
    this.player = {
      name: "",
      id: data.data.id,
      privateId: data.data.privateId,
      score: 0,
    };
    if (this.onAuth) {
      this.onAuth({
        id: data.data.id,
        privateId: data.data.privateId,
      });
    }
  }

  private onMessage(data: any) {
    const message = unpack(new Uint8Array(data));
    switch (message.type) {
      case Session_Event.STATUS:
        this.updatedStatus(message.data);
        break;
      default:
      case Session_Event.INTERACTION_RESPONSE:
        switch (message.data.type) {
          case Session_Interaction_Response.JOINED_SESSION:
            this.joinedSession(message.data);
            break;
          case Session_Interaction_Response.ERROR_JOINING_SESSION:
            if (this.onError) {
              this.onError(message.data.data.message);
            }
            break;
        }
    }
  }

  private interaction(type: Session_Interaction, data: any) {
    this.send({
      type: Session_Event.INTERACTION,
      data: {
        type,
        data,
      },
    });
  }

  // Server sent status update.
  public onStatusUpdate?: (status: Status) => void;

  // Server sent authentication details.
  public onAuth?: (details: { id?: string; privateId: string }) => void;

  // Server sent error.
  public onError?: (error: string) => void;

  answerQuestion(answerId: string) {
    if (this.type !== "player") {
      throw new Error("Only players can answer questions.");
    }
    if (this.player) {
      this.player.lastAnswerId = answerId;
    }

    this.interaction(Session_Interaction.ANSWER, {
      answerId,
    });
  }

  startGame() {
    if (this.type !== "host") {
      throw new Error("Only the host can start the game.");
    }
    this.interaction(Session_Interaction.START_GAME, {});
  }

  kickPlayer(playerId: string) {
    if (this.type !== "host") {
      throw new Error("Only the host can kick players.");
    }
    this.interaction(Session_Interaction.KICK_PLAYER, {
      playerId,
    });
  }

  displayAnswer() {
    if (this.type !== "host") {
      throw new Error("Only the host can display the answer.");
    }
    this.interaction(Session_Interaction.REVEAL_ANSWER, {});
  }

  displayLeaderboard() {
    if (this.type !== "host") {
      throw new Error("Only the host can display the leaderboard.");
    }
    this.interaction(Session_Interaction.SHOW_LEADERBOARD, {});
  }

  nextQuestion() {
    if (this.type !== "host") {
      throw new Error("Only the host can display the next question.");
    }
    this.interaction(Session_Interaction.NEXT_QUESTION, {});
  }

  endSession() {
    if (this.type !== "host") {
      throw new Error("Only the host can end the session.");
    }
    this.interaction(Session_Interaction.END_SESSION, {});
  }

  leaveSession() {
    this.interaction(Session_Interaction.LEAVE, {});
    this.socket.close();
  }

  // (Re)join the session.
  joinSession(sessionId: string, privateId?: string, name?: string) {
    if (!privateId && !name && this.type === "player") {
      throw new Error("No private ID or name provided.");
    }

    this.send({
      type: Session_Event.JOIN,
      data: {
        sessionId: sessionId,
        privateId,
        name,
      },
    });
  }
}
