import React from "react";

import {
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
} from "@mui/material";

import { faT, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AnswerCreationCardProps {
  onDeletion: (index: number) => void;
  onAnswerTextChange: (answer: string, index: number) => void;
  onAnswerCorrectChange: (correct: boolean, index: number) => void;
  answerNumber: number;
  answerText: string;
  correct: boolean;
}

export default function AnswerCreationCard(props: AnswerCreationCardProps) {
  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onAnswerTextChange(event.target.value, props.answerNumber - 1);
  };

  const handleCorrectChange = () => {
    props.onAnswerCorrectChange(!props.correct, props.answerNumber - 1);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <TextField
        id="answer-text"
        label="Answer"
        value={props.answerText}
        onChange={(e) =>
          handleAnswerChange(e as React.ChangeEvent<HTMLInputElement>)
        }
      />

      <FormControlLabel
        label="Correct"
        control={
          <Checkbox
            checked={props.correct}
            onChange={(e) => handleCorrectChange()}
          />
        }
      />
      <IconButton
        aria-label="delete"
        color="error"
        onClick={(e) => props.onDeletion(props.answerNumber - 1)}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </Card>
  );
}
