import { TextField, Typography, InputLabel, Button } from "@mui/material";
import { Box } from "@mui/system";
import NotStartedPlayer from "./not_started_player";

interface NotStartedScreenPlayer {
  name: string;
  onKick: () => void;
}

interface NotStartedScreenProps {
  name: string;
  code: string;
  players: NotStartedScreenPlayer[];
  onStart: () => void;
}

export default function NotStarted(props: NotStartedScreenProps) {
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography sx={{ p: 2 }} variant="h3" component="h2">
        {props.name}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          variant="filled"
          value={props.code}
          InputProps={{
            readOnly: true,
          }}
          sx={{
            input: {
              textAlign: "center",
              fontSize: 40,
              width: 200,
              py: 2,
            },
          }}
        />
        <Button
          variant="contained"
          onClick={props.onStart}
          sx={{
            mt: 2,
            width: 220,
            height: 50,
          }}
        >
          Start Game
        </Button>
        <Typography sx={{ mt: 2 }} variant="h6" component="h3">
          {props.players.length}{" "}
          {props.players.length === 1 ? "player" : "players"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",

          p: 2,
        }}
      >
        {props.players.map((player) => {
          return (
            <Box
              sx={{
                m: 2,
              }}
              key={`player-${player.name}-not-started-player`}
            >
              <NotStartedPlayer name={player.name} onKick={player.onKick} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
