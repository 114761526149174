import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Card,
  Typography,
  TextField,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import { supabase } from "../../helpers/supabase";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsCheckboxChecked, setTermsCheckboxChecked] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
  }>({
    open: false,
    message: "",
    severity: "error",
  });
  const [showPassword, setShowPassword] = useState(false);

  const authenticate = async (email: string, password: string) => {
    let { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });
    if (error) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }
    if (data) {
      setSnackbar({
        open: true,
        message: "Registration successful",
        severity: "success",
      });
      navigate("/");
      return;
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
  };

  const handleTermsCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTermsCheckboxChecked(event.target.checked);
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = () => {
    // Clear errors
    setEmailError("");
    setPasswordError("");

    // Check if email is valid
    const regex =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;
    if (!regex.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    // Check if password and confirm password match
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    // Check if checkbox is checked
    if (!termsCheckboxChecked) {
      alert("Please accept the terms of service and privacy policy");
      return;
    }

    // Call authentication function with email and password arguments
    authenticate(email, password);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          });
        }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {
            setSnackbar({
              ...snackbar,
              open: false,
            });
          }}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Card
        sx={{
          p: 2,
          m: 2,
          maxWidth: 600,
        }}
      >
        <Typography variant="h2" component="h1">
          Register
        </Typography>
        <form>
          <TextField
            required
            fullWidth
            margin="normal"
            label="Email Address"
            value={email}
            onChange={handleEmailChange}
            error={emailError !== ""}
            helperText={emailError}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError !== ""}
            helperText={passwordError}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  sx={{
                    height: 30,
                    width: 30,
                    m: 1,
                  }}
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </IconButton>
              ),
            }}
          />
          <TextField
            required
            fullWidth
            margin="normal"
            type="password"
            label="Confirm Password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            error={passwordError !== ""}
            helperText={passwordError}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={termsCheckboxChecked}
                onChange={handleTermsCheckboxChange}
                name="termsCheckbox"
              />
            }
            label={
              <>
                I have read and agree to the{" "}
                <Link href="#" underline="always">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link href="#" underline="always">
                  Privacy Policy
                </Link>
              </>
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              width: "100%",
            }}
          >
            Register
          </Button>
        </form>
      </Card>
    </Box>
  );
}
