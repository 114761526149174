import AnswerCard from "../answer_card";
import { Box } from "@mui/system";
import { Grid, Typography, Button } from "@mui/material";

interface QuestionStageProps {
  text: string; // Question text.
  points: number; // Question points.
  answers: {
    text: string;
    correct: boolean;
  }[]; // Question answers.
  answerRevealed: boolean; // Whether the answer has been revealed.
  onRevealAnswer?: () => void; // Callback for when the reveal answer button is clicked.
  onNextQuestion?: () => void; // Callback for when the next question button is clicked.
  onShowLeaderboard?: () => void; // Callback for when the show leaderboard button is clicked.
}

export default function QuestionStage(props: QuestionStageProps) {
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography variant="h2" component="h3">
        {props.text}
      </Typography>
      <Typography variant="h4" component="h4">
        {props.points} points
      </Typography>

      {props.answerRevealed ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={props.onShowLeaderboard}
            sx={{
              mr: 2,
            }}
          >
            Show leaderboard
          </Button>
          <Button variant="contained" onClick={props.onNextQuestion}>
            Next Question
          </Button>
        </Box>
      ) : (
        <Button variant="contained" onClick={props.onRevealAnswer}>
          Reveal Answer
        </Button>
      )}

      <Grid
        container
        spacing={2}
        columns={2}
        sx={{
          p: 2,
          flexGrow: 1,
        }}
      >
        {props.answers.map((answer, index) => {
          return (
            <Grid
              container
              item
              xs={6}
              direction="column"
              key={`question-answers-${index}`}
              sx={{
                height: 200,
              }}
            >
              <AnswerCard
                text={answer.text}
                correct={answer.correct}
                selected={false}
                revealed={props.answerRevealed}
                clickable={false}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
