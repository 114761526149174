import { Card, CardActionArea, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

interface AnswerCardProps {
  text: string;
  correct?: boolean;
  selected: boolean;
  clickable: boolean;
  revealed: boolean;
  onClick?: () => void;
}

export default function AnswerCard(props: AnswerCardProps) {
  const theme = useTheme();

  let color = theme.palette.primary.main;
  if (props.revealed) {
    if (props.correct) {
      color = theme.palette.success.main;
    } else if (props.selected) {
      color = theme.palette.error.main;
    }
  }

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <CardActionArea
        sx={{
          width: "100%",
          height: "100%",
          p: 2,
          ...(!props.clickable && {
            pointerEvents: "none",
          }),
          backgroundColor: color,
        }}
        onClick={props.onClick}
      >
        <Typography noWrap variant="h6" component="p">
          {props.text}
        </Typography>
      </CardActionArea>
    </Card>
  );
}
