import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  Typography,
  Card,
  Box,
  IconButton,
  CardActionArea,
} from "@mui/material";

export interface ElementListElementProps {
  order: number;
  id: string;
  title: string;
  content: string;
  selected: boolean;
  onClick: () => void;
  onDeletion: () => void;
}

export default function ElementListElement(props: ElementListElementProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "manipulation",
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "end",
          height: "100%",
        }}
      >
        <IconButton
          sx={{
            m: 1,
            width: 20,
            height: 20,
          }}
          aria-label="Delete"
          onClick={props.onDeletion}
        >
          <FontAwesomeIcon size="xs" icon={faTrash} />
        </IconButton>
      </Box>

      <Card
        variant="outlined"
        sx={{
          height: 100,
          width: 180,
          overflow: "hidden",
        }}
      >
        <CardActionArea
          sx={{
            p: 1,
            width: "100%",
            height: "100%",
          }}
          onClick={props.onClick}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {props.title}
          </Typography>
          <Box
            sx={{
              height: "100%",
              display: "flex",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                paddingX: 2,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {props.content}
            </Typography>
          </Box>
        </CardActionArea>
      </Card>
    </Box>
  );
}
