import axios, { AxiosError } from "axios";
import { supabase } from "../helpers/supabase";
import { GameCreation } from "../routes/create_game";

export async function hostGame(game: GameCreation) {
  let session = (await supabase.auth.getSession()).data.session;
  if (!session) {
    throw new Error("Authentication required to host a game.");
  }
  let response;
  try {
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/create_game`,
      {
        name: game.name,
        questions: game.questions,
      },
      {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      }
    );
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
  return response.data.session;
}

export async function findSession(code: string): Promise<string> {
  let response;
  try {
    response = await axios.get(
      `${process.env.REACT_APP_API_URL}/find_session`,
      { params: { code } }
    );
  } catch (error: any) {
    throw new Error(error?.response?.data?.message);
  }
  return response.data.id;
}
