import { Typography, Button, Card } from "@mui/material";
import { Box } from "@mui/system";
import LeaderboardPlayer from "./leaderboard_player";

interface LeaderboardStageProps {
  players: {
    name: string;
    score: number;
    onKick?: () => void;
  }[];
  onNextQuestion?: () => void;
}

export default function LeaderboardStage(props: LeaderboardStageProps) {
  let sortedPlayers = props.players.sort((a, b) => {
    return b.score - a.score;
  }); // Sort players by score
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyItems: "center",
        alignItems: "center",

        height: "100%",
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        sx={{
          m: 2,
        }}
      >
        Leaderboard
      </Typography>
      {props.onNextQuestion ? (
        <Button variant="contained" onClick={props.onNextQuestion}>
          Next Question
        </Button>
      ) : null}
      <Card
        sx={{
          width: "95%",
          p: 2,
          m: 2,
        }}
      >
        {sortedPlayers.slice(0, 5).map((player, index) => {
          return (
            <Box
              sx={{
                m: 2,
              }}
              key={`leaderboard-player-${index}`}
            >
              <LeaderboardPlayer rank={index + 1} {...player} />
            </Box>
          );
        })}
        {sortedPlayers.length === 0 ? (
          <Typography
            variant="h5"
            component="h2"
            sx={{
              m: "2",
              textAlign: "center",
            }}
          >
            No players
          </Typography>
        ) : null}
      </Card>
    </Box>
  );
}
