import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Box textAlign="center">
      <Typography variant="h1">404</Typography>
    </Box>
  );
}
