import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Root from "./routes/root";
import JoinGame from "./routes/join_game";
import Game from "./routes/game";
import CreateGame from "./routes/create_game";
import ManageGame from "./routes/manage_game";
import NotFound from "./routes/not_found";
import Home from "./routes/home";
import Login from "./routes/auth/login";
import Register from "./routes/auth/register";
import RequestPasswordReset from "./routes/auth/request_password_reset";
import MyAccount from "./routes/user/my_account";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "reset_password",
        element: <RequestPasswordReset />,
      },
      {
        path: "create_game",
        element: <CreateGame />,
      },
      {
        path: "manage_game",
        element: <ManageGame />,
      },
      {
        path: "join",
        element: <JoinGame />,
      },
      {
        path: "game/:sessionId",
        element: <Game />,
      },
      {
        path: "my_account",
        element: <MyAccount />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <CssBaseline />
    </ThemeProvider>
  </StyledEngineProvider>
);
