import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Connection, Status, Session_State } from "../api/ws";
import LeaderboardStage from "../components/leaderboard_stage";
import GameFooter from "../components/manage_game/game_footer";
import NotStarted from "../components/manage_game/not_started_stage";
import QuestionStage from "../components/manage_game/question_stage";
import { supabase } from "../helpers/supabase";

export default function ManageGame() {
  const navigate = useNavigate();
  const [sessionDetails, setSessionDetails] = useState<{
    id: string;
    privateId: string;
  } | null>(null);
  const [session, setSession] = useState<Status | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);

  if (!sessionDetails) {
    if (localStorage.getItem("sessionDetails")) {
      setSessionDetails(
        JSON.parse(localStorage.getItem("sessionDetails") || "")
      );
    }
  }

  useEffect(() => {
    const connect = async () => {
      let userSessionToken = (await supabase.auth.getSession()).data.session
        ?.access_token;

      if (!userSessionToken) {
        navigate("/login");
        return;
      }

      const newConnection = new Connection(
        new WebSocket(
          `${process.env.REACT_APP_WS_URL}/connect?token=${userSessionToken}`
        ),
        "host"
      );

      setConnection(newConnection);
    };

    if (!connection) {
      connect();
    }
  }, [connection, sessionDetails]);

  if (!connection) {
    return <div>Connecting...</div>;
  }

  connection.socket.onopen = () => {
    if (sessionDetails && !session) {
      connection.joinSession(sessionDetails.id, sessionDetails.privateId);
    }
  };

  connection.onStatusUpdate = (session) => {
    setSession(session);
  };
  const handleStart = () => {
    connection.startGame();
  };

  const handleRevealAnswer = () => {
    connection.displayAnswer();
  };

  const handleNextQuestion = () => {
    connection.nextQuestion();
  };

  const handleShowLeaderboard = () => {
    connection.displayLeaderboard();
  };

  if (!session) {
    return <div>Loading...</div>;
  }

  let stageElement = null;

  switch (session.state) {
    case Session_State.Created:
      let players = session.players.map((player) => {
        return {
          name: player.name,
          onKick: () => {
            connection.kickPlayer(player.id);
          },
        };
      });
      stageElement = (
        <NotStarted
          name={session.name}
          code={session.code}
          players={players}
          onStart={handleStart}
        />
      );
      break;
    case Session_State.Answering:
      let answers =
        session.currentQuestion?.answers?.map((answer) => {
          return {
            text: answer.answer,
            correct: answer.correct || false,
          };
        }) || [];

      stageElement = (
        <QuestionStage
          text={session.currentQuestion?.question || ""}
          points={session.currentQuestion?.points || 0}
          answerRevealed={false}
          answers={answers}
          onRevealAnswer={handleRevealAnswer}
        />
      );
      break;
    case Session_State.Answer_Displayed:
      let answers2 =
        session.currentQuestion?.answers?.map((answer) => {
          return {
            text: answer.answer,
            correct: answer.correct || false,
          };
        }) || [];

      stageElement = (
        <QuestionStage
          text={session.currentQuestion?.question || ""}
          points={session.currentQuestion?.points || 0}
          answerRevealed={true}
          answers={answers2}
          onNextQuestion={handleNextQuestion}
          onShowLeaderboard={handleShowLeaderboard}
        />
      );
      break;
    case Session_State.Leaderboard:
      let players2 = session.players.map((player) => {
        return {
          name: player.name,
          score: player.score,
          onKick: () => {
            connection.kickPlayer(player.id);
          },
        };
      });
      stageElement = (
        <LeaderboardStage
          players={players2}
          onNextQuestion={handleNextQuestion}
        />
      );
      break;
    default:
      stageElement = <div>Unknown stage</div>;
  }

  return (
    <Box>
      {stageElement}
      <GameFooter
        gameName={session.name}
        code={session.code}
        currentQuestionNumber={session.currentQuestionNumber}
        numberOfQuestions={session.numberOfQuestions}
      />
    </Box>
  );
}
