interface AuthError {
  error: string;
  error_code: number;
  error_description: string;
}

// Parses an error hash from the URL (if any).
// Example: #error=unauthorized_client&error_code=401&error_description=Email+link+is+invalid+or+has+expired
export function parseAuthErrorHash(hash: string): AuthError | null {
  // Removes the leading # and splits the key-value pairs.
  let keyValues = hash.substring(1).split("&");
  let error: null | {
    error: string;
    error_code: number;
    error_description: string;
  } = {
    error: "",
    error_code: 0,
    error_description: "",
  };
  for (let keyValue of keyValues) {
    let [key, value] = keyValue.split("=");
    switch (key) {
      case "error":
        error = {
          ...error,
          error: value,
        };
        break;
      case "error_code":
        error = {
          ...error,
          error_code: parseInt(value),
        };
        break;
      case "error_description":
        error = {
          ...error,
          error_description: decodeURIComponent(value.replace(/\+/g, "%20")),
        };
        break;
    }
  }
  if (error.error_code === 0) {
    return null;
  }
  return error;
}
