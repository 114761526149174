import { useState, useEffect } from "react";
import {
  Typography,
  Card,
  Box,
  TextField,
  Skeleton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { supabase } from "../../helpers/supabase";
import { User } from "@supabase/supabase-js";
import { useNavigate, useLocation } from "react-router-dom";

export default function MyAccount() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState<User | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "info" | "warning" | "error";
  }>({
    open: false,
    message: "",
    severity: "error",
  });

  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const { hash } = location;

  if (hash == "#change-email" && !changeEmailDialogOpen) {
    setChangePasswordDialogOpen(false);
    setChangeEmailDialogOpen(true);
  } else if (hash == "#change-password" && !changePasswordDialogOpen) {
    setChangeEmailDialogOpen(false);
    setChangePasswordDialogOpen(true);
  }

  const onEmailChange = async () => {
    try {
      supabase.auth.updateUser({ email: newEmail });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
      return;
    }

    setChangeEmailDialogOpen(false);
    setSnackbar({
      open: true,
      message: "Email address changed successfully",
      severity: "success",
    });
    return;
  };

  const onPasswordChange = async () => {
    if (newPassword !== newPasswordConfirm) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      await supabase.auth.updateUser({ password: newPassword });
    } catch (error: any) {
      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    }

    setChangePasswordDialogOpen(false);
    setSnackbar({
      open: true,
      message: "Password changed successfully",
      severity: "success",
    });
    return;
  };

  useEffect(() => {
    const getUser = async () => {
      const user = (await supabase.auth.getUser()).data.user;
      if (!user) {
        navigate("/login");
        return;
      }
      setUser(user);
    };

    if (!user) {
      getUser();
    }
  });

  if (!user) {
    return (
      <Card
        sx={{
          m: 4,
          p: 2,
        }}
      >
        <Typography variant="h3" component="h1">
          My Account
        </Typography>
        <Skeleton height={70} />
        <Skeleton height={70} />
        <Skeleton height={40} />
      </Card>
    );
  }

  return (
    <Card
      sx={{
        m: 4,
        p: 2,
      }}
    >
      <Typography variant="h3" component="h1">
        My Account
      </Typography>
      <Box
        sx={{
          m: 2,
        }}
      >
        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography variant="h5" component="h2">
            Account Details
          </Typography>
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
              },
              p: 2,
            }}
          >
            <TextField
              id="standard-read-only-input"
              label="Email Address"
              defaultValue={user.email}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              fullWidth
            />
            <Button
              sx={{
                ml: {
                  xs: 0,
                  sm: 2,
                },
                mt: {
                  xs: 2,
                  sm: 0,
                },
                width: {
                  xs: "100%",
                  sm: "auto",
                },
              }}
              variant="contained"
              onClick={() => setChangeEmailDialogOpen(true)}
            >
              Change {xs ? "Email Address" : ""}
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography variant="h5" component="h2">
            Security
          </Typography>
          <Button
            sx={{
              mt: 2,
              width: {
                xs: "100%",
                sm: "auto",
              },
            }}
            variant="contained"
            onClick={() => setChangePasswordDialogOpen(true)}
          >
            Change Password
          </Button>
        </Box>
      </Box>

      <Dialog
        open={changeEmailDialogOpen}
        onClose={() => setChangeEmailDialogOpen(false)}
      >
        <DialogTitle>Change Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A confirmation email will be sent to the new and old email
            addresses.
          </DialogContentText>
          <TextField
            autoFocus
            id="new-email-address"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={newEmail}
            error={emailError !== ""}
            helperText={emailError}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions onClick={() => setChangeEmailDialogOpen(false)}>
          <Button>Cancel</Button>
          <Button onClick={onEmailChange}>Change Email</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={changePasswordDialogOpen}
        onClose={() => setChangePasswordDialogOpen(false)}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="new-password"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
            value={newPassword}
            error={passwordError !== ""}
            helperText={passwordError}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="new-password-confirm"
            label="Confirm New Password"
            type="password"
            fullWidth
            variant="standard"
            value={newPasswordConfirm}
            error={passwordError !== ""}
            helperText={passwordError}
            onChange={(e) => setNewPasswordConfirm(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangePasswordDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onPasswordChange}>Change Password</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          });
        }}
        autoHideDuration={6000}
      >
        <Alert
          onClose={() => {
            setSnackbar({
              ...snackbar,
              open: false,
            });
          }}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Card>
  );
}
