import { Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";

interface GameFooterProps {
  playerName: string; // Player name
  gameName: string; // Game name
  score: number; // Player points
  code: string; // Game code
  currentQuestionNumber: number; // Current question number
  numberOfQuestions: number; // Total number of questions
}

export default function GameFooter(props: GameFooterProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        position: "fixed",
        bottom: 0,
        width: "100%",
        p: 1,
      }}
    >
      <Paper
        sx={{
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="h6" component="p">
          {props.score} Points
        </Typography>
      </Paper>
      <Typography variant="body1" component="p">
        {props.playerName} - {props.gameName}
      </Typography>
      <Paper
        sx={{
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="h6" component="p">
          {props.code}
        </Typography>
      </Paper>
      <Typography variant="body1" component="p">
        Question {props.currentQuestionNumber}/{props.numberOfQuestions}
      </Typography>
    </Box>
  );
}
