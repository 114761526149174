import { Box } from "@mui/system";
import { Card, CardActionArea, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface LeaderboardPlayerProps {
  name: string;
  score: number;
  rank: number;
  onKick?: () => void;
}

export default function LeaderboardPlayer(props: LeaderboardPlayerProps) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center",
      }}
    >
      <CardActionArea
        sx={{
          p: 2,
          ...(props.onKick && {
            "&:hover": {
              textDecoration: "line-through",
              textDecorationColor: theme.palette.error.main,
              color: theme.palette.error.main,
            },
          }),
          ...(!props.onKick && {
            pointerEvents: "none",
          }),
        }}
        onClick={props.onKick}
      >
        <Grid container>
          <Grid item xs={2}>
            <Typography noWrap variant="h6" component="p">
              {props.rank}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography noWrap variant="h6" component="p">
              {props.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography noWrap variant="h6" component="p">
              {props.score}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
}
