import { hostGame } from "../api/api";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import QuestionCreationCard, {
  QuestionCreationQuestion,
} from "../components/editor/question_creation_card";
import ElementListTab from "../components/editor/element_list_tab";

import { arrayMove } from "@dnd-kit/sortable";

import { Box, Button, TextField, Typography } from "@mui/material";
import { supabase } from "../helpers/supabase";

export interface GameCreation {
  name: string;
  questions: QuestionCreationQuestion[];
}

// Find the first available id for a question
function findAvailableId(questionList: QuestionCreationQuestion[]) {
  let id = 0;
  while (questionList.find((question) => question.id === id)) {
    id++;
  }
  return id;
}

export default function CreateGame() {
  const navigate = useNavigate();
  const [game, setGame] = useState<GameCreation>({
    name: "",
    questions: [
      {
        id: 0,
        selected: true,
        text: "",
        points: 50,
        answers: [
          {
            text: "",
            correct: false,
          },
          {
            text: "",
            correct: false,
          },
          {
            text: "",
            correct: false,
          },
          {
            text: "",
            correct: false,
          },
        ],
      },
    ],
  });
  const handleGameNameChange = (name: string) => {
    setGame({
      ...game,
      name: name,
    });
  };
  const handleOrderChange = (oldIndex: number, newIndex: number) => {
    setGame({
      ...game,
      questions: arrayMove(game.questions, oldIndex, newIndex),
    });
  };
  const handleQuestionChange = (
    question: QuestionCreationQuestion,
    index: number
  ) => {
    const newQuestions = [...game.questions];
    newQuestions[index] = question;
    setGame({
      ...game,
      questions: newQuestions,
    });
  };
  const handleQuestionDeletion = (index: number) => {
    let newQuestions = [...game.questions];
    newQuestions.splice(index, 1);

    if (newQuestions[index - 1]) {
      // If there is a question before the deleted one, select it
      newQuestions[index - 1].selected = true;
    } else if (newQuestions.length > 0) {
      // If there is no question before the deleted one (but there are questions), select the last one
      newQuestions[newQuestions.length - 1].selected = true;
    }
    setGame({
      ...game,
      questions: newQuestions,
    });
  };
  const handleNewQuestion = () => {
    const newQuestions = [...game.questions];

    // Deselect all questions
    newQuestions.forEach((question) => {
      question.selected = false;
    });

    newQuestions.push({
      id: findAvailableId(game.questions),
      selected: true,
      text: "",
      points: 50,
      answers: [
        {
          text: "",
          correct: false,
        },
        {
          text: "",
          correct: false,
        },
        {
          text: "",
          correct: false,
        },
        {
          text: "",
          correct: false,
        },
      ],
    });
    setGame({
      ...game,
      questions: newQuestions,
    });
  };

  const handleQuestionListSelection = (index: number) => {
    const newQuestions = [...game.questions];

    // Deselect all questions
    newQuestions.forEach((question) => {
      question.selected = false;
    });

    newQuestions[index].selected = true;
    setGame({
      ...game,
      questions: newQuestions,
    });
  };

  const handleHostGame = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let session = await hostGame(game);
    localStorage.setItem(
      "sessionDetails",
      JSON.stringify({
        id: session.id,
        privateId: session.privateId,
      })
    );
    navigate(`/manage_game`);
  };

  useEffect(() => {
    const getUser = async () => {
      let user = (await supabase.auth.getUser()).data.user;
      if (!user) {
        navigate("/login");
      }
    };

    getUser();
  });

  return (
    <Box>
      <Typography variant="h3" align="center" p={2}>
        Create Game
      </Typography>
      <form onSubmit={handleHostGame}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box textAlign="center">
            <TextField
              id="gameName"
              value={game.name}
              onChange={(e) => handleGameNameChange(e.target.value)}
              placeholder="Game Name"
              required
              sx={{
                mx: 2,
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                py: 2,
              }}
            >
              Host Game
            </Button>
          </Box>
          <Box display="flex" flexDirection="row" m={2}>
            <Typography variant="h5" mr={2}>
              {`${game.questions.length} ${
                game.questions.length === 1 ? "Question" : "Questions"
              }`}
            </Typography>
            <Button variant="contained" onClick={handleNewQuestion}>
              Add Question
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column-reverse",
              md: "row",
            },
          }}
        >
          <Box
            m={2}
            sx={{
              height: "65vh",
            }}
          >
            <ElementListTab
              elements={game.questions.map((question, index) => {
                return {
                  id: `element-list-element-${question.id}`,
                  order: index,
                  content: question.text,
                  title: `Question ${index + 1}`,
                  selected: question.selected,
                  onClick: () => handleQuestionListSelection(index),
                  onDeletion: () => handleQuestionDeletion(index),
                };
              })}
              onUpdateOrder={handleOrderChange}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              p: 2,
            }}
          >
            <QuestionCreationCard
              question={
                game.questions.find((question) => question.selected) ||
                game.questions[0]
              }
              onQuestionChange={(question) => {
                const index = game.questions.findIndex(
                  (q) => q.id === question.id
                );
                handleQuestionChange(question, index);
              }}
              onDeletion={() => {
                const index = game.questions.findIndex(
                  (q) => q.selected === true
                );
                handleQuestionDeletion(index);
              }}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
}
