import { Box } from "@mui/system";
import { Typography } from "@mui/material";

interface NotStartedProps {
  gameName: string; // Name of the game.
  playerName: string; // Name of the player.
  players: string[]; // List of player names.
}

export default function NotStartedStage(props: NotStartedProps) {
  return (
    <Box
      sx={{
        textAlign: "center",
        width: "100%",
        p: 2,
      }}
    >
      <Typography
        variant="h2"
        component="h1"
        sx={{
          m: 2,
        }}
      >
        {props.gameName}
      </Typography>
      <Typography
        variant="h4"
        component="h2"
        sx={{
          m: 2,
        }}
      >
        {props.playerName}
      </Typography>
      <Typography
        variant="h6"
        component="h3"
        sx={{
          m: 2,
        }}
      >
        Waiting for host to start game...
      </Typography>
    </Box>
  );
}
