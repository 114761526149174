import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Connection, Status, Session_State } from "../api/ws";
import EnterName from "../components/game/enter_name_stage";
import GameFooter from "../components/game/game_footer";
import NotStartedStage from "../components/game/not_started_stage";
import QuestionStage from "../components/game/question_stage";
import LeaderboardStage from "../components/leaderboard_stage";
import { supabase } from "../helpers/supabase";

export default function Game() {
  let { sessionId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [playerSessionDetails, setPlayerSessionDetails] = useState<{
    id?: string;
    privateId: string;
    lastAnsweredQuestionId?: string; // Id of the last question answered.
    lastAnswerId?: string; // Id of the last answer given.
  } | null>(null);
  const [session, setSession] = useState<Status | null>(null);
  const [connection, setConnection] = useState<Connection | null>(null);

  useEffect(() => {
    const connect = async () => {
      let url = `${process.env.REACT_APP_WS_URL}/connect`;
      let userSessionToken = (await supabase.auth.getSession()).data.session
        ?.access_token;

      if (userSessionToken) {
        url += `?token=${userSessionToken}`;
      }

      const newConnection = new Connection(new WebSocket(url), "player");

      setConnection(newConnection);
    };

    if (!connection) {
      connect();
    }
  }, [connection, playerSessionDetails]);

  if (!sessionId) {
    navigate("/join");
    return null;
  }

  if (!playerSessionDetails) {
    if (localStorage.getItem(sessionId)) {
      setPlayerSessionDetails(
        JSON.parse(localStorage.getItem(sessionId) || "")
      );
    }
  }

  if (!connection) {
    if (playerSessionDetails) {
      return <div>Connecting...</div>;
    }
    navigate("/join");
    return null;
  }

  connection.onStatusUpdate = (session) => {
    setSession(session);
  };

  connection.socket.onopen = () => {
    if (playerSessionDetails && !session && sessionId) {
      // Player previously joined the session, rejoin.
      connection.joinSession(sessionId, playerSessionDetails.privateId);
    }
  };

  connection.onAuth = (details) => {
    setPlayerSessionDetails(details);
    localStorage.setItem(sessionId || "", JSON.stringify(details));
  };

  connection.onError = (error) => {
    setError(error);
  };

  const handleJoinGame = (name: string) => {
    if (sessionId) {
      connection.joinSession(sessionId, undefined, name);
    }
  };

  if (!session) {
    return <EnterName handleJoinGame={handleJoinGame} error={error || null} />;
  }

  let stageElement = null;

  switch (session.state) {
    case Session_State.Created:
      stageElement = (
        <NotStartedStage
          gameName={session.name}
          playerName={connection?.player?.name || ""}
          players={session.players.map((p) => p.name)}
        />
      );
      break;
    case Session_State.Answering:
      let lastAnswerId = playerSessionDetails?.lastAnswerId;

      let answers2 =
        session.currentQuestion?.answers?.map((answer) => {
          return {
            text: answer.answer,
            correct: answer.correct || false,
            onAnswer: () => {
              let details = localStorage.getItem(sessionId || "");
              localStorage.setItem(
                sessionId || "",
                JSON.stringify({
                  ...JSON.parse(details || ""),
                  lastAnswerId: answer.id,
                  lastAnsweredQuestionId: session.currentQuestion?.id,
                })
              );
              if (playerSessionDetails) {
                setPlayerSessionDetails({
                  ...playerSessionDetails,
                  lastAnswerId: answer.id,
                  lastAnsweredQuestionId: session.currentQuestion?.id,
                });
              }

              connection.answerQuestion(answer.id);
            },
            selected: answer.id === lastAnswerId,
            clickable: session.currentQuestion?.answers?.find(
              (question) => question.id === lastAnswerId
            )
              ? false
              : true,
          };
        }) || [];
      stageElement = (
        <QuestionStage
          text={session.currentQuestion?.question || ""}
          points={session.currentQuestion?.points || 0}
          answerRevealed={false}
          answers={answers2}
          correct={false}
          answered={
            session.currentQuestion?.answers?.find(
              (question) => question.id === lastAnswerId
            ) !== undefined
          }
        />
      );
      break;
    case Session_State.Answer_Displayed:
      let lastAnswerId2 = playerSessionDetails?.lastAnswerId;

      let answers =
        session.currentQuestion?.answers?.map((answer) => {
          return {
            text: answer.answer,
            correct: answer.correct || false,
            selected: answer.id === lastAnswerId2,
            clickable: false,
          };
        }) || [];
      stageElement = (
        <QuestionStage
          text={session.currentQuestion?.question || ""}
          points={session.currentQuestion?.points || 0}
          answerRevealed={true}
          answers={answers}
          correct={
            session.currentQuestion?.answers?.find((a) => a.correct)?.id ===
            lastAnswerId2
          }
          answered={false}
        />
      );
      break;
    case Session_State.Leaderboard:
      stageElement = <LeaderboardStage players={session.players} />;
      break;

    default:
      stageElement = <div>Unknown stage</div>;
  }

  return (
    <div>
      {stageElement}
      <GameFooter
        gameName={session.name}
        playerName={connection?.player?.name || ""}
        code={session.code}
        currentQuestionNumber={session.currentQuestionNumber}
        numberOfQuestions={session.numberOfQuestions}
        score={connection?.player?.score || 0}
      />
    </div>
  );
}
